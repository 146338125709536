<template>
  <base-view id="flex tempates">
    <view-section>
      <div class="prose prose-md md:prose-lg">
        <h1>Setup Templates</h1>
        <h3>FTP Site: Brick Login</h3>
        <p>
          In order to login go
          <strong><a href="https://rmr.files.com/login?next=/">here</a></strong>
          and use the username and password provided by the account manager. If
          you do not have a login yet, reach out to your assigned account
          manager.
        </p>
        <img src="@/assets/ftp_login.png" alt="" />
        <p>
          Below is a view of the home page of your assigned Brick FTP folder.
          There are two main options on the sidebar, choosing Files or viewing
          sharing options.
        </p>
        <img src="@/assets/flex_screenshot_2.png" alt="" />
        <p>The view of your files is organized in a variety of folders.</p>
        <ul>
          <li>
            <h4>Plan Year:</h4>
            This is where we put past plan years information
          </li>
          <li>
            <h4>FSA-HSA:</h4>
            Enrollment/contribution templates that come inn
          </li>
          <li>
            <h4>COBRA:</h4>
            All COBRA files that come in
          </li>
          <li>
            <h4>File Feed Reports *:</h4>
            Client file feeds if not sent to the vendor
          </li>
          <li>
            <h4>Monthly Reports *:</h4>
            Your relationship manager might store contribution and distribution
            reports here
          </li>
        </ul>
        <p>* = Optional depending on your relationship manager set up</p>
        <img src="@/assets/flex_screenshot_3.png" alt="" />
        <p>
          As the employer contact you may add folders, create new shared
          settings for files and upload files for Rocky Mountain Reserve to
          process.
        </p>
        <img src="@/assets/flex_screenshot_4.png" alt="" />
        <p>
          If your company is going to to use a file feed vendor, the account
          manager will work with the vendor to ensure the files are deposited
          into the correct folder.
        </p>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
export default {
  components: {
    BaseView,
    ViewSection
  }
};
</script>
